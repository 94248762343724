import { Injectable, signal } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ViewportSize } from "../models/singleTypes/viewport-size.mode";
import { Subject, tap } from "rxjs";
import { fromEvent } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ResponsiveService {
  onWindowResize$ = new Subject<ViewportSize>();
  viewportSize = signal<ViewportSize>({ height: 0, width: 0 });

  constructor() {
    this.updateViewport();

    fromEvent(window, "resize")
      .pipe(
        takeUntilDestroyed(),
        tap(() => this.updateViewport())
      )
      .subscribe();
  }

  updateViewport() {
    const newViewportSize: ViewportSize = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
    this.viewportSize.set(newViewportSize);
    this.onWindowResize$.next(newViewportSize);
  }
}
