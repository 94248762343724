export enum ControlType {
    InputSelect,
    SearchSelect,
    TileSelect,
    Header,
    Image,
    SexPicker,
    VideoParameters,
    AiInput,
    CommentTypeSelect,
    SwitchableAi,
}
