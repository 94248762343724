export enum RouteUrls {
  Home = '',
  VideoFromSanta = 'video-from-santa',
  VideoGenerator = 'video-generator/:id',
  LetterFromSanta = 'letter-from-santa',
  Pricing = 'price-list',
  Account = 'account',
  Basket = 'basket',
  Order = 'order',
  OrderWithId = 'order/:id',
  AboutUs = 'about-us',
  Feedback = 'feedback',
  PageNotFound = 'page-not-found',
}
