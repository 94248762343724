import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private _subject = new Subject<Notification>();
  private _idx = 0;

  constructor() { }

  getObservable(): Observable<Notification> {
    return this._subject.asObservable();
  }

  showSuccess(message: string, timeout = 5000) {
    this._subject.next(new Notification(this._idx++, NotificationType.success, message, timeout));
  }

  showError(message: string, timeout = 5000) {
    this._subject.next(new Notification(this._idx++, NotificationType.error, message, timeout));
  }
}

export class Notification {
  constructor(
    public id: number,
    public type: NotificationType,
    public message: string,
    public timeout: number,
  ) { }
}

export enum NotificationType {
  success = 0,
  error = 1,
}