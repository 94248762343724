import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { environment } from '../environments/environment';
import { DeliveryTimeOption, QualityOption, VideoDeliveryTime, VideoQuality } from '../containers/video-generator-form/models/video-params.model';

export interface PriceList {
    qualityPrices: VideoQualityPrice[];
    deliveryTimePrices: VideoDelvieryTimePrice[],
}

export interface VideoQualityPrice {
    type: VideoQuality;
    price: number,
}

export interface VideoDelvieryTimePrice {
    type: VideoDeliveryTime;
    price: number,
    timeInMinutes: number,
}

@Injectable({
    providedIn: 'root',
})
export class PriceListService {
    private _destroyed$ = new Subject<void>();
    private readonly apiUrl = `${environment.apiUrl}/PriceLists`;

    constructor(private http: HttpClient) {
        http.get<PriceList>(this.apiUrl)
            .pipe(takeUntil(this._destroyed$))
            .subscribe((priceLists) => {
                for (let qualityPrice of priceLists.qualityPrices) {
                    const option = this.qualityOptions.find(x => x.value === qualityPrice.type);
                    option!.price = qualityPrice.price;

                    if (qualityPrice.price == 0) {
                        option!.displayPrice = "0"
                    }
                    else {
                        option!.displayPrice = `+ $${qualityPrice.price}`
                    }
                }

                for (let deliveryTimePrices of priceLists.deliveryTimePrices) {
                    const option = this.deliveryTimeOptions.find(x => x.value === deliveryTimePrices.type);
                    option!.price = deliveryTimePrices.price;

                    if (deliveryTimePrices.price == 0) {
                        option!.displayPrice = "0"
                    }
                    else {
                        option!.displayPrice = `+ $${deliveryTimePrices.price}`
                    }
                }
            });
    }

    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    getQualityOptions(): QualityOption[] {
        return this.qualityOptions;
    }

    getQualityOption(value: VideoQuality): QualityOption {
        var option = this.qualityOptions.find(x => x.value == value);

        if (!option) {
            throw Error("Quality option not found");
        }

        return option;
    }

    getDeliveryTimeOptions(): DeliveryTimeOption[] {
        return this.deliveryTimeOptions;
    }

    getDeliveryTimeOption(value: VideoDeliveryTime): DeliveryTimeOption {
        var option = this.deliveryTimeOptions.find(x => x.value == value);

        if (!option) {
            throw Error("Delivery time option not found");
        }

        return option;
    }

    private qualityOptions: QualityOption[] =
        [
            {
                displayName: 'Standard',
                details: '720p',
                tooltip: 'Standard quality, suitable for smaller phone and tablet screens.',
                value: VideoQuality._720p
            },
            {
                displayName: 'Great',
                details: 'Full HD',
                tooltip: 'Select this option to enjoy the highest picute quality on any device.',
                value: VideoQuality._1080p
            },
            {
                displayName: 'Remarkable',
                details: '4K',
                tooltip: 'No compromise. Enjoy your movie in 4K on any device.',
                value: VideoQuality._4K
            },
        ]

    private deliveryTimeOptions: DeliveryTimeOption[] =
        [
            {
                displayName: 'Standard',
                details: '24 hours',
                value: VideoDeliveryTime.Standard
            },
            {
                displayName: 'Express',
                details: '6 hours',
                value: VideoDeliveryTime.Express
            },
            {
                displayName: 'Super Express',
                details: '2 hours',
                value: VideoDeliveryTime.SuperExpress
            },
        ]
}

