import { Injectable } from '@angular/core';
import { BasketItem } from '../models/basketItem.model';
import { PriceListService } from '../../../services/price-lists.service';
import { DiscountType } from '../../../models/discountCode/enums/discountType.enum';

@Injectable({
  providedIn: 'root',
})
export class BasketItemsService {
  private _items: BasketItem[] = [];

  constructor(private readonly priceListService: PriceListService) {
    this._items = JSON.parse(localStorage.getItem('basketItems') || '[]');
  }

  remove(item: BasketItem) {
    const index = this._items.indexOf(item);
    this._items.splice(index, 1);
    this.syncItems();
  }

  add(item: BasketItem) {
    this._items.push(item);
    this.syncItems();
  }

  clear() {
    this._items = [];
    this.syncItems();
  }

  isBasketEmpty() {
    return !this.items?.length;
  }

  get length(): number {
    return this._items.length;
  }

  get total(): number {
    return Number(
      this._items.reduce((total, item) => total + item.price, 0).toFixed(2),
    );
  }

  get items() {
    return this._items.slice(0);
  }

  syncItems() {
    this.items.forEach((x) => {
      var price = x.basePrice;
      
      if (x.videoParams) {
        price += Number(
          (
            this.priceListService.getDeliveryTimeOption(
              x.videoParams!.deliveryTime,
            ).price! +
            this.priceListService.getQualityOption(x.videoParams!.quality)
              .price!
          ).toFixed(2),
        );
      }

      x.pricePreDiscount = price;

      if (x.discountCode) {
        if (x.discountCode!.discountType == DiscountType.Percentage) {
          price *= (100 - x.discountCode!.discountPercentageValue) / 100;
        } else {
          price = Math.max(price - x.discountCode!.discountAmountValue, 0);
        }
      }

      x.price = price;
    });

    localStorage.setItem('basketItems', JSON.stringify(this._items));
  }
}
