export interface VideoParams {
    quality: VideoQuality,
    deliveryTime: VideoDeliveryTime
}

export interface QualityOption {
    displayName: string,
    details: string,
    price?: number,
    displayPrice?: string,
    tooltip: string,
    value: VideoQuality,
}

export interface DeliveryTimeOption {
    displayName: string,
    details: string,
    price?: number,
    displayPrice?: string,
    value: VideoDeliveryTime,
}

export enum VideoQuality {
    _720p = 1,
    _1080p = 2,
    _4K = 3,
}

export enum VideoDeliveryTime {
    Standard = 1,
    Express = 2,
    SuperExpress = 3,
}

