import { CommonModule } from "@angular/common";
import {
  Component,
  ElementRef,
  Input,
  signal,
  viewChild
} from "@angular/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons";

export enum PositionClasses {
  right = "right",
  left = "left",
  center = "center",
}

@Component({
  selector: "app-tooltip",
  standalone: true,
  imports: [CommonModule, FontAwesomeModule],
  templateUrl: "./tooltip.component.html",
  styleUrl: "./tooltip.component.scss",
})
export class TooltipComponent {
  @Input() text: string;
  tooltipElement = viewChild<ElementRef>("tooltip");
  tooltipPositionClass = signal<string>(PositionClasses.right);

  readonly faCircleQuestion = faCircleQuestion;

  handleOpenTooltip() {
    const { right: rightPosition, left: leftPosition } =
      this.tooltipElement()?.nativeElement.getBoundingClientRect();
    const windowWidth = window.innerWidth;
    const staticTooltipSize = 320;

    if (windowWidth > rightPosition + staticTooltipSize) {
      this.tooltipPositionClass.set(PositionClasses.right);
    } else if (0 < leftPosition - staticTooltipSize) {
      this.tooltipPositionClass.set(PositionClasses.left);
    } else {
      this.tooltipPositionClass.set(PositionClasses.center);
    }
  }
}
