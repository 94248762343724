import { ComponentType, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { ComponentRef, Injectable, Injector } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private overlayRef: OverlayRef | null = null;

  constructor(
    private _injector: Injector,
    private _overlay: Overlay,
    private router: Router,
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe(() => {
        this.closeOverlay();
      });
  }

  openModal<T>(component: ComponentType<T>, token: object): ComponentRef<T> {
    this.overlayRef = this._overlay.create({
      positionStrategy: this._overlay.position().global().centerVertically(),
      disposeOnNavigation: true,
    });

    const injectionTokens = new WeakMap();
    injectionTokens.set(token, this.overlayRef);

    const popupPortal = new ComponentPortal(
      component,
      null,
      new PortalInjector(this._injector, injectionTokens),
    );
    return this.overlayRef.attach(popupPortal);
  }

  closeOverlay() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }
}
